import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from "@material-ui/core/styles";
import UploadPdfModal from "./UploadPdfModal";
import { useAuth } from "./../util/auth";
import { useGetBooks, useItemsByOwner } from "./../util/db";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function DashboardItems() {
  const classes = useStyles();

  const auth = useAuth();

  const {
    data: books,
    status: booksStatus,
    error: booksError,
  } = useGetBooks(auth.user.uid);

  const [uploadingPdf, setUploadingPdf] = useState(false);

  const booksAreEmpty = !books || books.length === 0;

  const downloadPdf = (id) => {
    const book = books.find((book) => book.id === id);
    if (!book) {
      alert(`Unable to download PDF with ID: ${id}`);
      return;
    } else {
      window.open(book.mp3Url, "_blank");
    }
  }

  return (
    <>
      {booksError && (
        <Box mb={3}>
          <Alert severity="error">{booksError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Audiobooks</Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => setUploadingPdf(true)}
          >
            Convert PDF
          </Button>
        </Box>
        <Divider />

        {(booksStatus === "loading" || booksAreEmpty) && (
          <Box py={5} px={3} align="center">
            {booksStatus === "loading" && <CircularProgress size={32} />}

            {booksStatus !== "loading" && booksAreEmpty && (
              <>Click the button to convert your first PDF.</>
            )}
          </Box>
        )}

        {booksStatus !== "loading" && books && books.length > 0 && (
          <List disablePadding={true}>
            {books.map((book, index) => (
              <ListItem
                key={index}
                divider={index !== books.length - 1}
              >
                <ListItemText>{book.name}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => downloadPdf(book.id)}
                  >
                    <GetAppIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>

      {uploadingPdf && <UploadPdfModal onDone={() => setUploadingPdf(false)} />}
    </>
  );
}

export default DashboardItems;
