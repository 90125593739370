import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { createBook } from "../util/db";
import { Checkbox, Typography } from "@material-ui/core";

const VOICES ={
  "English (US) Male": "en-US-Standard-A",
  "English (US) Female": "en-US-Standard-C",

  "English (Australia) Male": "en-AU-Standard-B",
  "English (Australia) Female": "en-AU-Standard-A",

  "English (India) Male": "en-IN-Standard-B",
  "English (India) Female": "en-IN-Standard-A",

  "English (UK) Male": "en-GB-Standard-B",
  "English (UK) Female": "en-GB-Standard-A",
}

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function UploadPdfModal(props) {
  const classes = useStyles();

  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState("English (US) Male");
  const [omitCitations, setOmitCitations] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = () => {
    if (selectedFile) {
      setPending(true);

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('voice', VOICES[selectedVoice]);
      formData.append('omit_citations', omitCitations);

      const name = selectedFile.name.split(".pdf")[0];

      const query = createBook(auth.user, name, formData);

      query
        .then(() => {
          // Let parent know we're done so they can hide modal
          props.onDone();
        })
        .catch((error) => {
          // Hide pending indicator
          setPending(false);
          // Show error alert message
          setFormAlert({
            type: "error",
            message: error.message,
          });
        });    
    } else {
      alert('Please select a PDF file to upload.');
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const canSelectVoice = auth.user && (auth.user.planId === "premium" || auth.user.planId === "pro");

  if (pending) {
    return (
      <Dialog open={true}>
        <Box py={5} px={3} align="center">
          <Typography variant="h6" paragraph={true}><span>Loading... This may take a few minutes</span></Typography>
          <CircularProgress size={32} />
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onClose={props.onDone}>
      <DialogTitle>
        Convert PDF
      </DialogTitle>
      <DialogContent className={classes.content}>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <input type="file" accept=".pdf" onChange={handleFileChange} />
            </Grid>
            <Grid item={true} xs={12}>
              <label>
                <strong>Omit Citations: </strong>
              </label>
              <Checkbox name="omitCitations" onClick={() => setOmitCitations(!omitCitations)} />
            </Grid>
            {canSelectVoice && (
              <Grid item={true} xs={12}>
                <label>
                  <strong>Select a Voice: </strong>
                </label>
                <select name="voice" onChange={e => setSelectedVoice(e.target.value)}>
                  {Object.keys(VOICES).map((voice) => (
                    <option key={voice} value={voice}>
                      {voice}
                    </option>
                  ))}
                </select>
              </Grid>
            )}
            <Grid item={true} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
                onClick={handleSubmit(onSubmit)}
              >
                <span>Convert</span>
              </Button>
            </Grid>
          </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default UploadPdfModal;
