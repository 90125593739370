import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import northeastern_seal from "../images/northeastern_seal.svg";
import jsu_seal from "../images/jsu_seal.svg";
import harvard from "../images/harvard.svg";
import ga_tech from "../images/ga_tech.svg";

function ClientsSection(props) {
  const items = [
    {
      name: "Harvard University",
      image: harvard,
      width: "120px",
      height: "120px",
    },
    {
      name: "Northeastern University",
      image: northeastern_seal,
      width: "120px",
      height: "120px",
    },
    {
      name: "Georgia Institute of Technology",
      image: ga_tech,
      width: "120px",
      height: "120px",
    },
    {
      name: "Jacksonville State University",
      image: jsu_seal,
      width: "120px",
      height: "120px",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
          />
          <Grid container={true} justifyContent="center">
            {items.map((item, index) => (
              <Grid item={true} xs={12} md="auto" key={index}>
                <Box py={2} px={3}>
                  <img src={item.image} height={item.height} width={item.width} alt={item.name}/>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default ClientsSection;
